import {HttpClient, HttpEvent, HttpParams, HttpResponse} from "@angular/common/http";
import {firstValueFrom, lastValueFrom, take} from "rxjs";

export const multipartFormData = { 'Content-Type': 'multipart/form-data' };
export const applicationJsonHeader = { 'Content-Type': 'application/json' };

export type THeader = {[p: string]: string | string[]}

export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE',
}

export abstract class ApiRequest {
  abstract get httpMethod(): HttpMethod;

  protected constructor(
    protected url: string,
    protected headers?: THeader | undefined,
    protected secure: boolean = true,
    protected unauthorizedRedirect: boolean = true,
  ) {}

  get requestHeaders(): THeader {
    return {
      ...this.headers,
      ...applicationJsonHeader,
    };
  }

  abstract getResponse(): Promise<Object>;
}

export class GetRequest extends ApiRequest {
  constructor(
    private http: HttpClient,
    url: string,
    headers?: THeader,
    secure: boolean = true,
    unauthorizedRedirect: boolean = true,
    private query?: HttpParams,
  ) {
    super(url, headers, secure, unauthorizedRedirect);
  }

  get httpMethod() {
    return HttpMethod.get;
  }

  async getResponse<T = Object>(): Promise<T> {
    const request = this.http.get(
      this.url,
      {
        headers: this.requestHeaders,
        params: this.query,
        withCredentials: this.secure,
      }
    ).pipe(take(1));

    return await lastValueFrom(request) as T;
  }
}

export class PostRequest extends ApiRequest {
  constructor(
    private http: HttpClient,
    url: string,
    private body: any,
    headers?: THeader,
    secure: boolean = true,
    unauthorizedRedirect: boolean = true,
    private query?: HttpParams,
  ) {
    super(url, headers, secure, unauthorizedRedirect);
  }

  get httpMethod() {
    return HttpMethod.post;
  }

  async getResponse<T = {[k: string]: any}>(): Promise<T> {
    const request = this.http.post(
      this.url,
      this.body,
      {
        headers: this.requestHeaders,
        withCredentials: this.secure,
      }
    ).pipe(take(1));

    return await lastValueFrom(request) as T;
  }

  async getResponseText(): Promise<string> {
    const request = this.http.post(
      this.url,
      this.body,
      {
        headers: this.requestHeaders,
        withCredentials: this.secure,
        responseType: 'text'
      }
    ).pipe(take(1));

    return await lastValueFrom(request);
  }

  async getFormDataResponse(): Promise<{[k: string]: any}> {
    const request = this.http.post(
      this.url,
      this.body,
      {
        withCredentials: this.secure,
      }
    ).pipe(take(1));

    return await lastValueFrom(request);
  }
}

export class PatchRequest extends ApiRequest {
  constructor(
    private http: HttpClient,
    url: string,
    private body: any,
    headers?: THeader,
    secure: boolean = true,
    unauthorizedRedirect: boolean = true,
    private query?: HttpParams,
  ) {
    super(url, headers, secure, unauthorizedRedirect);
  }

  get httpMethod() {
    return HttpMethod.post;
  }

  async getResponse<T = {[k: string]: any}>(): Promise<T> {
    const request = this.http.patch(
      this.url,
      this.body,
      {
        headers: this.requestHeaders,
        withCredentials: this.secure,
      }
    ).pipe(take(1));

    return await lastValueFrom(request) as T;
  }
}

export class PutRequest extends ApiRequest {
  constructor(
    private http: HttpClient,
    url: string,
    private body: any,
    headers?: THeader,
    secure: boolean = true,
    unauthorizedRedirect: boolean = true,
    private query?: HttpParams,
  ) {
    super(url, headers, secure, unauthorizedRedirect);
  }

  get httpMethod() {
    return HttpMethod.post;
  }

  async getResponse<T = {[k: string]: any}>(): Promise<T> {
    const request = this.http.put(
      this.url,
      this.body,
      {
        headers: this.requestHeaders,
        withCredentials: this.secure,
      }
    ).pipe(take(1));

    return await lastValueFrom(request) as T;
  }
}

export class DeleteRequest extends ApiRequest {
  constructor(
    private http: HttpClient,
    url: string,
    headers?: THeader,
    secure: boolean = true,
    unauthorizedRedirect: boolean = true,
    private query?: HttpParams,
  ) {
    super(url, headers, secure, unauthorizedRedirect);
  }

  get httpMethod() {
    return HttpMethod.post;
  }

  async getResponse(): Promise<Object> {
    const request = this.http.delete(
      this.url,
      {
        headers: this.requestHeaders,
        withCredentials: this.secure,
      }
    ).pipe(take(1));

    return await lastValueFrom(request);
  }
}
